<template>
  <div class="container-fluid bg-white p-5 rounded-lg">
    <div class="col-12 d-flex pl-0">
      <p class="my-auto mr-auto">
        <i style="font-size: 22px" :class="$route.meta.iconClass"></i>

        <span style="font-size: 22px; color: #7f7f7f" class="font-weight-light">
          Fiche vision:
        </span>
        <span class="font-weight-bold text-black" style="font-size: 25px">{{
          VISION.libelle
        }}</span>
      </p>

      <div class="row mb-3">
        <div class="col-12 px-0">
          <modal-actions
            mode="show"
            :disable="!disable"
            @print="
              $router.push({
                name: 'print-vision',
                params: {
                  slug: selectedVisionItem.slug,
                  id: selectedVisionItem.id,
                },
              })
            "
            @close="
              $router.go($store.getters['routerHistory/fromCreation'] ? -2 : -1)
            "
          />
        </div>
      </div>
    </div>

    <div id="add-page" class="row col-12 p-0 mx-auto">
      <div class="col-md-4 col-12 p-1">
        <strategic-vision
          :fileModActivated="!desactivateAllFileModifications"
          @disableButtons="disableButtons"
          @modifyFileDo="
            () => {
              desactivateAllFileModifications = true;
            }
          "
          :disable="disable"
          :vision="selectedVisionItem"
        />
      </div>

      <div class="col-md-4 col-12 p-1">
        <Progress
          margin
          @leaveChange="desactivateAllFileModifications = false"
          :fileModActivated="!desactivateAllFileModifications"
          @disableButtons="disableButtons"
          @modifyFileDo="
            () => {
              desactivateAllFileModifications = true;
            }
          "
          :disable="disable"
          :vision="selectedVisionItem"
        />
      </div>

      <div class="col-md-4 col-12 p-1">
        <coverage
          @leaveChange="desactivateAllFileModifications = false"
          :fileModActivated="!desactivateAllFileModifications"
          @disableButtons="disableButtons"
          @modifyFileDo="
            () => {
              desactivateAllFileModifications = true;
            }
          "
          :disable="disable"
          :vision="selectedVisionItem"
        />
      </div>
    </div>
    <hr />
    <div class="row col-12 p-0 mx-auto">
      <div class="col-12 px-0">
        <b-tabs
          v-model="activeTab"
          @activate-tab="
            (newTabIndex) => $store.dispatch('tabs/changeTab', newTabIndex)
          "
          class="tab-solid tab-solid-primary"
        >
          <b-tab title="Objectifs strategiques">
            <div class="d-flex mb-2">
              <span class="h4 text-secondary mr-auto my-auto"
                >Liste des objectifs strategiques
              </span>

              <div class="d-flex justify-content-end">
                <customAddButton
                  :disabled="!(isAdmin || isManager || isSuper)"
                  text="Nouvel objectif"
                  :disabeleAlignment="!disable"
                  @click="
                    $router.push({
                      name: 'add-objectif',
                      params: {
                        fromVision: true,
                      },
                    })
                  "
                ></customAddButton>
              </div>
            </div>
            <div class="col-12 p-0">
              <hr class="mt-1 mb-3 bg-secondary" />
            </div>
            <strategic-goal-list
              :objectifs="objectifs"
              :fields="strategicGoal.tableFields"
              :responsables="responsables"
              :organisations="organisations"
              @cancelItem="removeEditableStrategicGoal"
              @confirmItem="addObject"
              @deleteItem="deleteItem"
              @editItem="editItem"
              :perPage="5"
              :visionName="VISION.libelle"
            />
          </b-tab>
          <div v-if="showAddObjectifsModal" to="modals-xyz-500">
            <Modal @close="showAddObjectifsModal = false">
              <AddObjectif
                :responsables="customResponsables"
                :organisations="customOrganisations"
                @close="showAddObjectifsModal = false"
                :visionName="VISION.libelle"
              />
            </Modal>
          </div>
          <b-tab title="Carthographie de déploiement" lazy>
            <div class="my-2 w-100" id="mapboxcustom">
              <map-box
                :countries="countries"
                :vision="selectedVisionItem"
                :contr="contr"
              />
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import Coverage from "./components/Coverage.vue";
import StrategicVision from "./components/StrategicVision.vue";
import Progress from "./components/Progress.vue";
import StrategicGoalList from "./components/StrategicGoalList.vue";
import AddObjectif from "./components/AddObjectif.vue";
import ModalActions from "@/components/custom/ModalActions.vue";
import MapBox from "@/components/custom/MapBox.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import Swal from "sweetalert2";
import Modal from "@/components/custom/Modal.vue";
import customAddButton from "@/components/custom/CustomAddButton";
import { datePickerFormat } from "@/helpers/DatePickerFormat.js";
import { concat } from "lodash";

export default {
  components: {
    MapBox,
    Coverage,
    StrategicVision,
    customAddButton,
    Progress,
    StrategicGoalList,
    ModalActions,
    Modal,
    AddObjectif,
  },
  data() {
    return {
      activeTab: this.$store.state.tabs.activeTab,
      showAddObjectifsModal: false,
      disable: false,
      vision: {
        libelle: null,
        description: null,
        organisation: null,
        responsable: null,
        objectifs: [],
        progress: 0,
        startedAt: moment().format("DD/MM/YYYY"),
        status: null,
      },

      objectifs: [],
      strategicGoal: {
        tableItems: [],
        tableFields: [
          {
            key: "libelle",
            label: "Objectif Stratégique",
            sortable: true,
          },
          {
            key: "organisationResp",
            label: "Organisation responsable",
            sortable: true,
          },
          { key: "responsable", label: "Responsable", sortable: true },
          // { key: "kpis", label: "KPIs", sortable: true },
          { key: "avancement", label: "Avancement", sortable: true },
          { key: "feuilleRoutes", label: "Alignement", sortable: true },
          {
            key: "actions",
            label: "Actions",
            sortable: false,
            thStyle: {
              width: "150px !important",
              minWidth: "150px !important",
            },
          },
        ],
      },

      showFdrList: false,
      countries: [
        {
          name: "Brésil",
          code: "BR",
          mapCode: "BRA",
          coordinates: [-47.8823, -15.7934],
          entities: ["entité 1", "entité 2", "entité 3"],
        },
        {
          name: "États-Unis",
          code: "US",
          mapCode: "USA",
          coordinates: [-95.8587, 40.6765],
          entities: ["entité 1", "entité 2"],
        },
        {
          name: "Australie",
          code: "AU",
          mapCode: "AUS",
          coordinates: [150.924, -33.7638],
          entities: ["entité 1"],
        },
        {
          name: "Russie",
          code: "RU",
          mapCode: "RUS",
          coordinates: [37.6031, 55.7421],
          entities: ["entité 1"],
        },
      ],
      selectedVisionItem: { libelle: "" },
      contr: null,
      selectedGoals: [],
      desactivateAllFileModifications: false,
    };
  },
  methods: {
    datePickerFormat,
    disableButtons(data) {
      return (this.disable = data);
    },
    showFdrListModal() {
      this.showFdrList = !this.showFdrList;
    },

    addEditableStrategicGoal() {
      this.strategicGoal.tableItems.push({ editMode: true });
    },
    removeEditableStrategicGoal(item, itemIndex) {
      var objectif = this.objectifs.find(
        (objectif, index) => index === itemIndex
      );
      if (this.shallowEqual(objectif)) {
        if (objectif.old !== true)
          this.objectifs = this.objectifs.filter(
            (objectif, index) => index !== itemIndex
          );
        else {
          this.$store.dispatch("vision/fetchVision", this.$route.params.id); // get current
        }
      } else {
        this.objectifs = this.objectifs.map((objectif, index) =>
          index === itemIndex
            ? {
                old: objectif.old,
                responsable: "",
                desription: "",
                avancement: 0,
                libelle: "",
                organisationResp: "",
                kpis: [],
                actions: ["delete"],
                editMode: true,
              }
            : objectif
        );
      }
    },
    shallowEqual: function (objectif) {
      return objectif.responsable === "" &&
        objectif.libelle === "" &&
        objectif.avancement === 0 &&
        objectif.organisationResp === "" &&
        objectif.desription === "" &&
        objectif.editMode === true
        ? true
        : false;
    },
    addObjectifs() {
      this.objectifs = !this.objectifs.some((obj) => obj.editMode)
        ? [
            {
              responsable: "",
              desription: "",
              avancement: 0,
              libelle: "",
              organisationResp: "",
              kpis: [],
              actions: ["delete"],
              editMode: true,
            },
            ...this.objectifs,
          ]
        : this.objectifs;
    },

    addObject: function (index, objectif) {
      this.objectifs = this.objectifs.map((obj, i) =>
        i === index
          ? {
              libelle: objectif.libelle,
              old: true,
              desription: objectif.desription,
              kpis: objectif.kpis,
              responsable: this.RESPONSABLES.find(
                (respo) => respo.id === objectif.responsable
              ),
              vision: this.VISION.find(
                (vision) => vision.id === objectif.vision
              ),
              organisationResp: this.ORGANISATIONS.find(
                (organisation) => organisation.id === objectif.organisationResp
              ),
              actions: ["delete"],
            }
          : obj
      );

      this.$store.dispatch("vision/updateVision", {
        id: this.$route.params.id,
        objectifs: this.objectifs.map((obj) => ({
          libelle: "" + obj.libelle,
          avancement: "" + obj.avancement,
          kpis: obj.kpis || [],
          desription: obj.desription,
          organisationResp: obj.organisationResp["@id"],
          responsable: `/api/users/${obj.responsable.id}`,
        })),
      });
    },
    deleteItem(index, data) {
      Swal.fire({
        title: "Etes-vous sûr(e) ?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Annuler",
        confirmButtonText: "Confirmer",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("objectif/deleteObjectif", data.id);
          Swal.fire("Supprimé!", "", "success");
          this.objectifs.splice(index, 1);
        }
      });
      //  this.$store.dispatch("objectif/deleteObjectif",data);
    },
    editItem(id) {
      this.objectifs = this.objectifs.filter((objectif) => objectif.old); // delete incommited objectifs
      this.objectifs = this.objectifs.map((objectif) =>
        id === objectif.id
          ? {
              ...objectif,
              responsable: `/api/users/${objectif.responsable.id}`,
              organisationResp: objectif.organisationResp["@id"],
              editMode: true,
            }
          : objectif
      );
    },
  },
  watch: {
    VISION() {
      this.selectedVisionItem = {
        ...this.VISION,
        responsable: this.VISION.responsable || null,
      };
      var table = ["in", "iso_3166_1"];
      var contr = [];
      if (this.VISION.couvertures)
        for (var i = 0; i < this.VISION.couvertures.length; i++) {
          contr[i] = this.VISION.couvertures[i].countryCode.toUpperCase();
        }
      for (var j = 0; j < contr.length; j++) {
        if (contr[j] == "MA") contr.push("EH");
      }
      table = concat(table, contr);
      this.contr = table;
      this.objectifs = this.VISION.objectifs
        ? this.VISION.objectifs.map((objectif) => ({
            ...objectif,
            old: true,
            actions: ["delete"],
          }))
        : this.objectifs;
    },
  },
  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("status/fetchVisionStatus");
    this.$store.dispatch("status/fetchAllStatus");
    this.$store.dispatch("organisation/fetchAllOrganisations");
    this.$store
      .dispatch("vision/fetchVision", this.$route.params.id)
      .then(() => {
        this.$store.dispatch("loader/toggleLoading", false);
      });
  },
  computed: {
    ...mapGetters("users", ["RESPONSABLES"]), //get resposables
    ...mapGetters("status", ["VISIONS_STATUS", "STATUS"]), //get all status
    ...mapGetters("organisation", ["ORGANISATIONS"]), //get all organisations
    ...mapGetters("vision", ["VISION"]), //get current vision
    ...mapGetters(["AuthenticatedUser", "isAdmin", "isSuper", "isManager"]),

    customResponsables() {
      return this.responsables.map((responsable) => ({
        value: `/api/users/${responsable.id}`,
        text: ` ${responsable.firstname}  ${responsable.lastname}`,
      }));
    },
    customOrganisations() {
      return this.organisations.map((organisation) => ({
        value: organisation["@id"],
        text: organisation.libelle,
      }));
    },

    responsables() {
      //return array of responsables
      return this.RESPONSABLES.map((responsable) => ({
        ...responsable,
        full_name: responsable.firstname + " " + responsable.lastname,
      }));
    },
    statusVisions() {
      return this.VISIONS_STATUS;
    },
    organisations() {
      // return this.ORGANISATIONS;
      return this.isAdmin || this.isSuper
        ? this.ORGANISATIONS
        : this.AuthenticatedUser &&
          this.AuthenticatedUser.organisationRatachement
        ? [this.AuthenticatedUser.organisationRatachement]
        : [];
    },
  },
};
</script>

<style scoped>
#mycontainer {
  height: 70vh;
}
</style>
<style>
#mapboxcustom .mapboxgl-popup div:nth-child(2) {
  background-color: rgb(197, 224, 180) !important;
  border-radius: 3rem !important;
  width: 190% !important;
  height: 12rem !important;
}
</style>
