<template>
  <EditGenericCard
    :editMode="editMode"
    :fileModActivated="fileModActivated"
    @modifyFileDoc="
      () => {
        editMode = !editMode;
        $emit('modifyFileDo');
      }
    "
  >
    <template v-slot:title>
      <p class="h4 my-auto" style="margin: 8.078px 0 !important">Couvertures</p>
      <div>
        <a class="mt-2" v-if="(isAdmin || isSuper || isManager) && !editMode"
          ><i
            @click="(editMode = !editMode), $emit('disableButtons', editMode)"
            :style="{
              color: variantr(disable),
              pointerEvents: curs(disable),
              display: curs(disableButton),
            }"
            class="mdi mdi-file-document-edit-outline"
            style="
              cursor: pointer;
              font-size: 35px;
              float: right;
              margin-top: -9%;
            "
          ></i
        ></a>
        <div
          v-else-if="isAdmin || isSuper || isManager"
          class="m-0 d-flex p-0"
          style="float: right"
        >
          <div>
            <b-button
              style="width: 90px"
              type="submit"
              class="btn btn-secondary rounded-pill"
              variant="secondary"
              size="sm"
              @click="test(), $emit('disableButtons', editMode)"
              :disabled="submitingForm"
            >
              <b-spinner
                v-if="submitingForm"
                small
                class="text-white"
                label="Loading..."
              ></b-spinner>
              Confirmer</b-button
            >
            <b-button
              @click="
                (editMode = !editMode),
                  $emit('disableButtons', editMode),
                  annulationCouverture()
              "
              class="btn btn-secondary rounded-pill"
              style="background-color: #adb5bd; border-color: #adb5bd"
              size="sm"
              >Annuler</b-button
            >
          </div>
        </div>
      </div>
    </template>
    <template v-slot:edit>
      <Places :testCouverture="sendingFunction" />

      <div class="row m-0 p-0">
        <div
          style="height: 50px; position: relative; overflow: visible"
          class="m-1"
          :class="{
            shdow: !$route.path.includes('print'),
          }"
          v-for="(covert, index) in vision.couvertures"
          :key="index"
        >
          <p>
            <country-flag :country="covert.countryCode" size="small" />{{
              covert.country || ""
            }}
          </p>

          <div style="position: absolute; top: -5px; right: -5px">
            <span style="cursor: pointer"
              ><i
                @click="deleteCouverture(covert.id, index)"
                class="mdi mdi-close-circle text-danger"
              ></i
            ></span>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:noEdit>
      <div
        class="text-center text-gray col-12 p-1"
        v-if="!vision.couvertures || !vision.couvertures.length"
      >
        Aucune couverture
      </div>
      <div class="row m-0 p-0">
        <div
          style="height: 50px; position: relative; overflow: visible"
          class="m-1"
          :class="{
            shdow: !$route.path.includes('print'),
            shadow: $route.path.includes('print'),
            'd-flex': $route.path.includes('print'),
            'px-2': $route.path.includes('print'),
            h3: $route.path.includes('print'),
          }"
          v-for="(covert, index) in vision.couvertures"
          :key="index"
        >
          <p
            :class="{
              'my-auto': $route.path.includes('print'),
            }"
          >
            <country-flag :country="covert.countryCode" size="small" />{{
              covert.country || ""
            }}
          </p>
        </div>
      </div>
    </template>
  </EditGenericCard>
</template>

<script>
import EditGenericCard from "./EditGenericCard.vue";
import CountryFlag from "vue-country-flag";
import Places from "@/components/custom/Places.vue";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  name: "Coverage",
  components: {
    EditGenericCard,
    CountryFlag,
    Places,
  },
  props: {
    disable: Boolean,
    disableButton: Boolean,
    vision: Object,
    fileModActivated: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    delete: false,
    idCouverture: [],
    index: 0,
    editMode: false,
    submitingForm: false,
    coverture: "",
    location: {
      adresse: "",
      country: "",
      lat: "",
      lng: "",
      countryCode: "",
    },
    deletedCouvertures: [],
    newCovertures: [],
  }),
  methods: {
    clone(data) {
      var loc = new Array();
      loc.country = data.country;
      loc.countryCode = data.countryCode;
      loc.lat = data.lat;
      loc.lng = data.lng;
      loc.adresse = data.adresse;
      return loc;
    },
    sendingFunction: function (location) {
      if (
        !this.vision.couvertures.find(
          (couverture) => couverture.countryCode === location.countryCode
        )
      )
        this.vision.couvertures.push(this.clone(location)) &&
          this.newCovertures.push(this.clone(location));
      else
        Swal.fire(
          "Impossible d'ajouter une couverture déjà existante !",
          "",
          "error"
        );
    },
    variantr(data) {
      if (data) {
        return "gray";
      }
    },
    curs(data) {
      if (data) {
        return "none";
      }
    },
    placepressKey: function (location) {
      this.vision.couvertures.push(this.clone(location));
    },
    annulationCouverture() {
      this.$store.dispatch("vision/fetchVision", this.$route.params.id);
    },
    test: function () {
      this.$store
        .dispatch("vision/fetchVision", this.$route.params.id)
        .then(() => {
          var newCovertures = this.newCovertures.filter(
            (element) => !element.id
          );

          newCovertures.forEach((element, index) => {
            this.$store
              .dispatch("couverture/createCouverture", {
                ...element,
                vision: `/api/strategy/visions/${this.$route.params.id}`,
              })
              .then(() => {
                this.$store.dispatch(
                  "vision/fetchVision",
                  this.$route.params.id
                );
                if (index === newCovertures.length - 1) {
                  !this.deletedCouvertures.length &&
                    Swal.fire("Couverture ajoutée avec succès", "", "success");
                  newCovertures = this.newCovertures = [];
                }
              });
          });

          // this.submitingForm = false;
          // this.editMode = this.editMode;
          this.$emit("leaveChange");

          this.deletedCouvertures.forEach((id, index) => {
            this.$store.dispatch("couverture/deleteCouverture", id).then(() => {
              this.$store.dispatch("vision/fetchVision", this.$route.params.id);
              if (index === this.deletedCouvertures.length - 1) {
                Swal.fire(
                  "La couverture est modifiée avec succès !",
                  "",
                  "success"
                );
                this.deletedCouvertures = [];
              }
            });
          });
          this.submitingForm = false;
          this.editMode = !this.editMode;
          this.$emit("leaveChange");
          this.$emit("disableButtons", this.editMode);
        });
    },
    deleteCouverture(id, index) {
      id && this.deletedCouvertures.push(id);
      this.vision.couvertures.splice(index, 1);
    },
    addCoverture(data) {
      this.location = data;
    },
  },
  computed: {
    ...mapGetters(["isAdmin", "isSuper", "isManager"]),
  },
};
</script>

<style scoped>
.shdow {
  min-height: 1% !important;
}
</style>
