<template>
  <div
    class="col-12 p-0 h-100"
    :class="{
      shdow: !$route.path.includes('print'),
    }"
  >
    <b-card v-if="fullWidthMode">
      <div class="d-flex justify-content-between align-items-center mb-4">
        <slot name="header"></slot>
      </div>
      <slot name="body"></slot>
    </b-card>
    <div
      v-else
      class="h-100"
      :class="{
        shdow: !$route.path.includes('print'),
        shadow: $route.path.includes('print'),
        'p-3': $route.path.includes('print'),
      }"
    >
      <div class="justify-content-between align-items-center mb-4">
        <slot name="header"></slot>
      </div>
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fullWidthMode: {
      type: Boolean,
      default: false,
    },
    margin: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style>
/* .shdow {
  min-height: 21rem;
} */
</style>
