var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EditGenericCard',{attrs:{"editMode":_vm.editMode,"fileModActivated":_vm.fileModActivated},on:{"modifyFileDoc":() => {
      _vm.editMode = !_vm.editMode;
      _vm.$emit('modifyFileDo');
    }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('p',{staticClass:"h4 my-auto",staticStyle:{"margin":"8.078px 0 !important"}},[_vm._v("Couvertures")]),_c('div',[((_vm.isAdmin || _vm.isSuper || _vm.isManager) && !_vm.editMode)?_c('a',{staticClass:"mt-2"},[_c('i',{staticClass:"mdi mdi-file-document-edit-outline",staticStyle:{"cursor":"pointer","font-size":"35px","float":"right","margin-top":"-9%"},style:({
            color: _vm.variantr(_vm.disable),
            pointerEvents: _vm.curs(_vm.disable),
            display: _vm.curs(_vm.disableButton),
          }),on:{"click":function($event){(_vm.editMode = !_vm.editMode), _vm.$emit('disableButtons', _vm.editMode)}}})]):(_vm.isAdmin || _vm.isSuper || _vm.isManager)?_c('div',{staticClass:"m-0 d-flex p-0",staticStyle:{"float":"right"}},[_c('div',[_c('b-button',{staticClass:"btn btn-secondary rounded-pill",staticStyle:{"width":"90px"},attrs:{"type":"submit","variant":"secondary","size":"sm","disabled":_vm.submitingForm},on:{"click":function($event){_vm.test(), _vm.$emit('disableButtons', _vm.editMode)}}},[(_vm.submitingForm)?_c('b-spinner',{staticClass:"text-white",attrs:{"small":"","label":"Loading..."}}):_vm._e(),_vm._v(" Confirmer")],1),_c('b-button',{staticClass:"btn btn-secondary rounded-pill",staticStyle:{"background-color":"#adb5bd","border-color":"#adb5bd"},attrs:{"size":"sm"},on:{"click":function($event){(_vm.editMode = !_vm.editMode),
                _vm.$emit('disableButtons', _vm.editMode),
                _vm.annulationCouverture()}}},[_vm._v("Annuler")])],1)]):_vm._e()])]},proxy:true},{key:"edit",fn:function(){return [_c('Places',{attrs:{"testCouverture":_vm.sendingFunction}}),_c('div',{staticClass:"row m-0 p-0"},_vm._l((_vm.vision.couvertures),function(covert,index){return _c('div',{key:index,staticClass:"m-1",class:{
          shdow: !_vm.$route.path.includes('print'),
        },staticStyle:{"height":"50px","position":"relative","overflow":"visible"}},[_c('p',[_c('country-flag',{attrs:{"country":covert.countryCode,"size":"small"}}),_vm._v(_vm._s(covert.country || "")+" ")],1),_c('div',{staticStyle:{"position":"absolute","top":"-5px","right":"-5px"}},[_c('span',{staticStyle:{"cursor":"pointer"}},[_c('i',{staticClass:"mdi mdi-close-circle text-danger",on:{"click":function($event){return _vm.deleteCouverture(covert.id, index)}}})])])])}),0)]},proxy:true},{key:"noEdit",fn:function(){return [(!_vm.vision.couvertures || !_vm.vision.couvertures.length)?_c('div',{staticClass:"text-center text-gray col-12 p-1"},[_vm._v(" Aucune couverture ")]):_vm._e(),_c('div',{staticClass:"row m-0 p-0"},_vm._l((_vm.vision.couvertures),function(covert,index){return _c('div',{key:index,staticClass:"m-1",class:{
          shdow: !_vm.$route.path.includes('print'),
          shadow: _vm.$route.path.includes('print'),
          'd-flex': _vm.$route.path.includes('print'),
          'px-2': _vm.$route.path.includes('print'),
          h3: _vm.$route.path.includes('print'),
        },staticStyle:{"height":"50px","position":"relative","overflow":"visible"}},[_c('p',{class:{
            'my-auto': _vm.$route.path.includes('print'),
          }},[_c('country-flag',{attrs:{"country":covert.countryCode,"size":"small"}}),_vm._v(_vm._s(covert.country || "")+" ")],1)])}),0)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }