<template>
  <EditGenericCard
    :margin="margin"
    :editMode="editMode"
    :fileModActivated="fileModActivated"
    @modifyFileDoc="
      () => {
        editMode = !editMode;
        $emit('modifyFileDo');
      }
    "
  >
    <template v-slot:title>
      <p class="h4 my-auto" style="margin: 8.078px 0 !important">Avancement</p>

      <div>
        <a class="mt-2" v-if="(isAdmin || isSuper || isManager) && !editMode"
          ><i
            @click="
              (editMode = !editMode),
                visionupdateProgress(),
                $emit('disableButtons', editMode)
            "
            :style="{
              color: variantr(disable),
              pointerEvents: curs(disable),
              display: curs(disableButton),
            }"
            class="mdi mdi-file-document-edit-outline"
            style="cursor: pointer; font-size: 35px; float: right; margin-top: -9%"
          ></i
        ></a>
        <div
          v-else-if="isAdmin || isSuper || isManager"
          class="m-0 d-flex p-0"
          style="float: right"
        >
          <b-button
            style="width: 90px"
            type="submit"
            class="btn btn-secondary rounded-pill"
            variant="secondary"
            size="sm"
            @click="test()"
            :disabled="submitingForm"
          >
            <b-spinner
              v-if="submitingForm"
              small
              class="text-white"
              label="Loading..."
            ></b-spinner>
            Confirmer</b-button
          >
          <b-button
            @click="(editMode = !editMode), $emit('disableButtons', editMode)"
            class="btn btn-secondary rounded-pill"
            style="background-color: #adb5bd; border-color: #adb5bd"
            size="sm"
            >Annuler</b-button
          >
        </div>
      </div>
    </template>
    <template v-slot:edit>
      <div style="padding: 0px 20px" class="row">
        <div class="col-12 m-0 p-0">
          <b-form-group
            label="Début:"
            class="font-weight-bold"
            label-cols-sm="3"
            label-align-sm="left"
          >
            <date-picker
              class="col-12 p-0 mb-2 font-weight-normal form-control p-0"
              style="padding-right: 0 !important; height: 44.22px; border: 1px"
              format="DD/MM/YYYY"
              placeholder="Date de début"
              v-model="date.startedat"
              :input-attr="{
                class: {
                  'is-invalid': $v.date.startedat.$error && $v.date.startedat.$dirty,
                  ' form-control': true,
                },
              }"
            />
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.date.startedat.$dirty"
            >
              {{ !$v.date.startedat.required ? "Champ obligatoire" : "" }}
            </span>
          </b-form-group>

          <b-form-group
            label="Fin:"
            class="font-weight-bold"
            label-cols-sm="3"
            label-align-sm="left"
          >
            <date-picker
              v-model="date.endingAt"
              class="col-12 p-0 mb-2 font-weight-normal"
              style="padding-right: 0 !important; height: 44.22px; border: 1px"
              placeholder="Date Fin"
              format="DD/MM/YYYY"
              :input-attr="{
                class: {
                  'is-invalid': $v.date.endingAt.$error && $v.date.endingAt.$dirty,
                  ' form-control': true,
                },
              }"
            />
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.date.endingAt.$dirty"
            >
              {{
                !$v.date.endingAt.required
                  ? "Champ obligatoire"
                  : $v.date.endingAt.required && $v.newInitiative.endingAt.$error
                  ? "La date de fin ne doit pas être antérieur à la date de début"
                  : ""
              }}
            </span>
          </b-form-group>

          <!-- <b-form-input id="range-2" type="range" min="0" max="100" step="1"></b-form-input> -->
        </div>
      </div>
    </template>
    <template v-slot:noEdit>
      <div class="my-4">
        <span>Début : </span>
        <span class="text-black font-weight-bold">{{ vision.startedAt }}</span>
      </div>
      <div class="my-4">
        <span>Fin : </span>
        <span class="text-black font-weight-bold">{{ vision.endingAt }}</span>
      </div>
      <div class="my-4 d-flex">
        <span>Avancement : </span>

        <span class="col-6 my-auto ml-1 p-0">
          <b-progress max="100" show-progress height="15px">
            <b-progress-bar
              :style="`background: ${
                vision.avancement > 20 && vision.avancement <= 70
                  ? '#f5f245'
                  : vision.avancement > 70
                  ? '#23d92c'
                  : '#f5ba45'
              }`"
              :value="vision.avancement"
              :label="`${vision.avancement}%`"
            ></b-progress-bar>
          </b-progress>
        </span>
      </div>
      <!-- <div
        style="flex: 1; flex-wrap: inherit; align-items: center"
        class="my-2 row p-0 m-0 w-100"
      >
        <div>
          <span class="mr-2">Scoring :</span>
        </div>
        <span class="my-auto">
          <b-badge
            v-if="vision.scoring > 5"
            variant="success"
            class="text-success mt-1"
            style="width: 30px; color: white !important"
          >
            {{ vision.scoring != 0 ? vision.scoring : "" }}
          </b-badge>
          <b-badge
            v-else-if="vision.scoring >= 1 && vision.scoring < 5"
            variant="warning"
            class="text-warning"
            style="width: 30px; color: white !important"
          >
            {{ vision.scoring != 0 ? vision.scoring : "" }}
          </b-badge>
          <b-badge
            v-else
            variant="danger "
            class="text-danger"
            style="width: 30px; color: white !important"
          >
            {{ vision.scoring != 0 ? vision.scoring : "" }}
          </b-badge></span
        >
      </div> -->
    </template>
  </EditGenericCard>
</template>

<script>
import EditGenericCard from "./EditGenericCard.vue";
import Swal from "sweetalert2";
import moment from "moment";
import { datePickerFormat } from "@/helpers/DatePickerFormat.js";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
export default {
  components: {
    EditGenericCard,
  },
  data: () => ({
    editMode: false,
    submitingForm: false,
    date: {
      startedat: null,
      endingAt: null,
    },
  }),
  validations: {
    date: {
      startedat: {
        required,
      },
      endingAt: {
        required,
        minValue(val) {
          return this.date.startedat
            ? moment(val, "DD/MM/YYYY").diff(moment(this.date.startedat, "DD/MM/YYYY")) +
                1 >
                0
            : true;
        },
      },
    },
  },
  props: {
    disable: Boolean,
    disableButton: Boolean,
    fileModActivated: {
      type: Boolean,
      default: true,
    },
    vision: Object,
    margin: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    vision() {
      this.date.startedat = datePickerFormat(this.vision.startedAt);
      this.date.endingAt = datePickerFormat(this.vision.endingAt);
    },
  },
  methods: {
    datePickerFormat,
    variant: function (progress) {
      if (progress <= 20) return "#f77f00";
      else if (progress >= 71) return "#2A8947";
      else if (progress >= 21 && progress <= 70) return "#fed713";
      return "red";
    },
    variantr(data) {
      if (data) {
        return "gray";
      }
    },
    curs(data) {
      if (data) {
        return "none";
      }
    },
    visionupdateProgress() {},
    test() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var item = {
          id: this.$route.params.id,
          endingAt: moment(this.date.endingAt).format("DD/MM/YYYY"),
          startedAt: moment(this.date.startedat).format("DD/MM/YYYY"),
        };
        this.$store.dispatch("vision/updateVision", item).then(() => {
          Swal.fire("La vision est mise à jour !", "", "success");
          this.submitingForm = false;
          this.editMode = false;
          this.$emit("leaveChange");
          this.$emit("disableButtons", this.editMode);

          this.vision = {
            ...this.vision,
            endingAt: this.vision.endingAt,
          };
        });
      }
    },
  },
  computed: {
    ...mapGetters(["isAdmin", "isSuper", "isManager"]),
  },
};
</script>

<style>
.disabled {
  cursor: not-allowed !important;
  color: gray o !important;
}
</style>
