var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid bg-white p-5 rounded-lg"},[_c('div',{staticClass:"col-12 d-flex pl-0"},[_c('p',{staticClass:"my-auto mr-auto"},[_c('i',{class:_vm.$route.meta.iconClass,staticStyle:{"font-size":"22px"}}),_c('span',{staticClass:"font-weight-light",staticStyle:{"font-size":"22px","color":"#7f7f7f"}},[_vm._v(" Fiche vision: ")]),_c('span',{staticClass:"font-weight-bold text-black",staticStyle:{"font-size":"25px"}},[_vm._v(_vm._s(_vm.VISION.libelle))])]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-12 px-0"},[_c('modal-actions',{attrs:{"mode":"show","disable":!_vm.disable},on:{"print":function($event){return _vm.$router.push({
              name: 'print-vision',
              params: {
                slug: _vm.selectedVisionItem.slug,
                id: _vm.selectedVisionItem.id,
              },
            })},"close":function($event){return _vm.$router.go(_vm.$store.getters['routerHistory/fromCreation'] ? -2 : -1)}}})],1)])]),_c('div',{staticClass:"row col-12 p-0 mx-auto",attrs:{"id":"add-page"}},[_c('div',{staticClass:"col-md-4 col-12 p-1"},[_c('strategic-vision',{attrs:{"fileModActivated":!_vm.desactivateAllFileModifications,"disable":_vm.disable,"vision":_vm.selectedVisionItem},on:{"disableButtons":_vm.disableButtons,"modifyFileDo":() => {
            _vm.desactivateAllFileModifications = true;
          }}})],1),_c('div',{staticClass:"col-md-4 col-12 p-1"},[_c('Progress',{attrs:{"margin":"","fileModActivated":!_vm.desactivateAllFileModifications,"disable":_vm.disable,"vision":_vm.selectedVisionItem},on:{"leaveChange":function($event){_vm.desactivateAllFileModifications = false},"disableButtons":_vm.disableButtons,"modifyFileDo":() => {
            _vm.desactivateAllFileModifications = true;
          }}})],1),_c('div',{staticClass:"col-md-4 col-12 p-1"},[_c('coverage',{attrs:{"fileModActivated":!_vm.desactivateAllFileModifications,"disable":_vm.disable,"vision":_vm.selectedVisionItem},on:{"leaveChange":function($event){_vm.desactivateAllFileModifications = false},"disableButtons":_vm.disableButtons,"modifyFileDo":() => {
            _vm.desactivateAllFileModifications = true;
          }}})],1)]),_c('hr'),_c('div',{staticClass:"row col-12 p-0 mx-auto"},[_c('div',{staticClass:"col-12 px-0"},[_c('b-tabs',{staticClass:"tab-solid tab-solid-primary",on:{"activate-tab":(newTabIndex) => _vm.$store.dispatch('tabs/changeTab', newTabIndex)},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('b-tab',{attrs:{"title":"Objectifs strategiques"}},[_c('div',{staticClass:"d-flex mb-2"},[_c('span',{staticClass:"h4 text-secondary mr-auto my-auto"},[_vm._v("Liste des objectifs strategiques ")]),_c('div',{staticClass:"d-flex justify-content-end"},[_c('customAddButton',{attrs:{"disabled":!(_vm.isAdmin || _vm.isManager || _vm.isSuper),"text":"Nouvel objectif","disabeleAlignment":!_vm.disable},on:{"click":function($event){return _vm.$router.push({
                    name: 'add-objectif',
                    params: {
                      fromVision: true,
                    },
                  })}}})],1)]),_c('div',{staticClass:"col-12 p-0"},[_c('hr',{staticClass:"mt-1 mb-3 bg-secondary"})]),_c('strategic-goal-list',{attrs:{"objectifs":_vm.objectifs,"fields":_vm.strategicGoal.tableFields,"responsables":_vm.responsables,"organisations":_vm.organisations,"perPage":5,"visionName":_vm.VISION.libelle},on:{"cancelItem":_vm.removeEditableStrategicGoal,"confirmItem":_vm.addObject,"deleteItem":_vm.deleteItem,"editItem":_vm.editItem}})],1),(_vm.showAddObjectifsModal)?_c('div',{attrs:{"to":"modals-xyz-500"}},[_c('Modal',{on:{"close":function($event){_vm.showAddObjectifsModal = false}}},[_c('AddObjectif',{attrs:{"responsables":_vm.customResponsables,"organisations":_vm.customOrganisations,"visionName":_vm.VISION.libelle},on:{"close":function($event){_vm.showAddObjectifsModal = false}}})],1)],1):_vm._e(),_c('b-tab',{attrs:{"title":"Carthographie de déploiement","lazy":""}},[_c('div',{staticClass:"my-2 w-100",attrs:{"id":"mapboxcustom"}},[_c('map-box',{attrs:{"countries":_vm.countries,"vision":_vm.selectedVisionItem,"contr":_vm.contr}})],1)])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }