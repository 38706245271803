<template>
  <EditGenericCard
    :editMode="editMode"
    :fileModActivated="fileModActivated"
    @modifyFileDoc="
      () => {
        editMode = !editMode;
        $emit('modifyFileDo');
      }
    "
    :fullWidthMode="false"
  >
    <template v-slot:title>
      <p class="h4 my-auto" style="margin: 8.078px 0 !important">
        Informations générales
      </p>

      <div class="my-auto">
        <a class="my-auto" v-if="(isAdmin || isSuper || isManager) && !editMode"
          ><i
            @click="
              (editMode = !editMode),
                updateVision(),
                $emit('disableButtons', editMode)
            "
            :style="{
              color: variantr(disable),
              pointerEvents: curs(disable),
              display: curs(disableButton),
            }"
            class="mdi mdi-file-document-edit-outline"
            style="
              cursor: pointer;
              font-size: 35px;
              float: right;
              margin-top: -9%;
            "
          ></i
        ></a>
        <div
          v-else-if="isAdmin || isSuper || isManager"
          class="m-0 d-flex p-0"
          style="float: right"
        >
          <b-button
            style="width: 90px"
            type="submit"
            class="btn btn-secondary rounded-pill"
            variant="secondary"
            size="sm"
            @click="test()"
            :disabled="submitingForm"
          >
            <b-spinner
              v-if="submitingForm"
              small
              class="text-white"
              label="Loading..."
            ></b-spinner>
            Confirmer</b-button
          >
          <b-button
            @click="(editMode = !editMode), $emit('disableButtons', editMode)"
            class="btn btn-secondary rounded-pill"
            style="background-color: #adb5bd; border-color: #adb5bd"
            size="sm"
            >Annuler</b-button
          >
        </div>
      </div>
    </template>
    <template v-slot:edit>
      <div class="col-12 m-0 p-0">
        <!-- {{ $v }} -->
        <b-form-group
          label="Libellé:"
          class="font-weight-bold col-12 p-0"
          label-cols-sm="4"
          label-align-sm="left"
        >
          <b-form-input
            type="text"
            class="form-control"
            placeholder="Libellé"
            v-model="vision.libelle"
            :class="{
              'is-invalid':
                $v.vision.libelle.$error && $v.vision.libelle.$dirty,
            }"
          />
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.vision.libelle.$dirty"
          >
            {{
              !$v.vision.libelle.required
                ? "champ obligatoire"
                : !$v.vision.libelle.minLength
                ? `Le champ doit contenir au moins ${$v.vision.libelle.$params.minLength.min} caractères.`
                : ""
            }}
          </span>
        </b-form-group>

        <b-form-group
          label="Description:"
          class="font-weight-bold col-12 p-0"
          label-cols-sm="4"
          label-align-sm="left"
        >
          <b-form-textarea
            class="form-control h-100"
            id="exampleFormControlTextarea1"
            rows="3"
            placeholder="Description"
            v-model="vision.description"
          ></b-form-textarea>
        </b-form-group>
        <b-form-group
          label="Responsable:"
          class="font-weight-bold col-12 p-0"
          label-cols-sm="4"
          label-align-sm="left"
        >
          <div
            class="font-weight-normal p-0 form-control"
            style="padding: 0 !important; height: fit-content"
          >
            <!-- :class="{
              'is-invalid':
                $v.selectedResponsable.$error && $v.selectedResponsable.$dirty,
            }" -->
            <v-select
              v-model="selectedResponsable"
              :options="responsables"
              placeholder="Responsable"
              class="bg-transparent"
              name="responsable"
              label="full_name"
              :reduce="(responsable) => responsable.id"
            />
          </div>
          <!-- <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.selectedResponsable.$dirty"
          >
            {{ !$v.selectedResponsable.required ? "champ obligatoire" : "" }}
          </span> -->
        </b-form-group>

        <b-form-group
          label="Organisation:"
          class="font-weight-bold col-12 p-0"
          label-cols-sm="4"
          label-align-sm="left"
        >
          <div
            class="font-weight-normal p-0 form-control"
            style="padding: 0 !important; height: fit-content"
            :class="{
              'is-invalid':
                $v.selectedOrganisationResp.$error &&
                $v.selectedOrganisationResp.$dirty,
            }"
          >
            <v-select
              v-model="selectedOrganisationResp"
              :options="organisationResp"
              placeholder="Organisation"
              class="bg-transparent"
              name="organisation"
              label="libelle"
              :reduce="(organisation) => organisation.id"
            />
          </div>
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.selectedOrganisationResp.$dirty"
          >
            {{
              !$v.selectedOrganisationResp.required ? "champ obligatoire" : ""
            }}
          </span>
        </b-form-group>

        <b-form-group
          label="Statut:"
          class="font-weight-bold col-12 p-0"
          label-cols-sm="4"
          label-align-sm="left"
        >
          <div
            class="font-weight-normal p-0 form-control"
            style="padding: 0 !important; height: fit-content"
            :class="{
              'is-invalid': $v.vision.status.$error && $v.vision.status.$dirty,
            }"
          >
            <v-select
              class="font-weight-normal form-control p-0 bg-transparent"
              style="padding-right: 0 !important ; border: 1px"
              v-model="vision.status"
              :options="statusVisions"
              placeholder="Statut"
            />
          </div>
          <span
            style="font-size: x-small"
            class="text-danger font-weight-normal"
            v-if="$v.vision.status.$dirty"
          >
            {{ !$v.vision.status.required ? "champ obligatoire" : "" }}
          </span>
        </b-form-group>
      </div>
    </template>
    <template v-slot:noEdit>
      <p style="color: black">
        Libellé :
        <span class="font-weight-bold" style="color: black">{{
          vision.libelle
        }}</span>
      </p>
      <p style="color: black">
        Date de création :
        <span class="font-weight-bold" style="color: black">{{
          moment(vision.createdAt, "DD/MM/YYYY").format("DD/MM/YYYY")
        }}</span>
      </p>
      <p style="color: black">
        Description :
        <span class="font-weight-bold" style="color: black">{{
          vision.description
        }}</span>
      </p>
      <p style="color: black">
        Responsable :
        <span
          v-if="vision.responsable"
          class="font-weight-bold"
          style="color: black"
          >{{
            vision.responsable.firstname + " " + vision.responsable.lastname
          }}</span
        >
      </p>
      <p style="color: black">
        Statut :
        <span class="font-weight-bold" style="color: black">{{
          vision.status
        }}</span>
      </p>
      <p style="color: black">
        Organisation responsable :
        <span
          v-if="vision.organisation"
          class="font-weight-bold"
          style="color: black"
          >{{ vision.organisation.libelle }}</span
        >
      </p>
    </template>
  </EditGenericCard>
</template>

<script>
import EditGenericCard from "./EditGenericCard.vue";
import Swal from "sweetalert2";
import { required, minLength } from "vuelidate/lib/validators";

import { mapGetters } from "vuex";

export default {
  name: "StrategicVision",
  components: {
    EditGenericCard,
  },
  props: {
    disable: Boolean,
    disableButton: Boolean,
    vision: {
      type: Object,
    },
    fileModActivated: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    submitingForm: false,
    editMode: false,
    selectedResponsable: null,
    selectedOrganisationResp: null,
    vision_libelle: "",
  }),
  validations: {
    // selectedResponsable: {
    //   required,
    // },
    selectedOrganisationResp: {
      required,
    },
    vision: {
      libelle: {
        required,
        minLength: minLength(4),
      },

      status: {
        required,
      },
    },
  },
  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("status/fetchVisionStatus");
    this.$store.dispatch("status/fetchAllStatus");
    this.$store.dispatch("organisation/fetchAllOrganisations");
  },
  computed: {
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("status", ["VISIONS_STATUS", "STATUS"]),
    ...mapGetters("organisation", ["ORGANISATIONS"]),
    ...mapGetters(["AuthenticatedUser", "isAdmin", "isSuper", "isManager"]),
    ...mapGetters("vision", ["VISION"]), //get current vision

    responsables() {
      return this.RESPONSABLES.map((responsable) => ({
        id: `/api/users/${responsable.id}`,
        full_name: responsable.firstname + " " + responsable.lastname,
        value: responsable.id,
        text: responsable.firstname + " " + responsable.lastname,
      }));
    },
    organisationResp() {
      var orgas =
        this.isAdmin || this.isSuper
          ? this.ORGANISATIONS
          : this.AuthenticatedUser &&
            this.AuthenticatedUser.organisationRatachement
          ? [this.AuthenticatedUser.organisationRatachement]
          : [];
      return orgas.map((organisation) => ({
        id: `/api/referentiel/organisations/${organisation.id}`,
        libelle: organisation.libelle,
        value: organisation.id,
        text: organisation.libelle,
      }));
    },
    statusVisions() {
      return this.VISIONS_STATUS;
    },
  },

  methods: {
    updateVision() {
      this.vision_libelle = this.vision.libelle;
      this.selectedResponsable = this.vision.responsable
        ? `/api/users/${this.vision.responsable.id}`
        : null;
      this.selectedOrganisationResp = `/api/referentiel/organisations/${this.vision.organisation.id}`;
    },
    variantr(data) {
      if (data) {
        return "gray";
      }
    },
    curs(data) {
      if (data) {
        return "none";
      }
    },
    test() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var item = {
          id: this.$route.params.id,
          description: this.vision.description,
          libelle: this.vision.libelle,
          status: this.vision.status,
          organisation: `${this.selectedOrganisationResp}`,
          responsable: this.selectedResponsable || null,
        };
        this.$store.dispatch("vision/updateVision", item).then(() => {
          Swal.fire("La vision est mise à jour !", "", "success");
          this.submitingForm = false;
          this.editMode = false;
          this.$emit("leaveChange");
          this.$emit("disableButtons", this.editMode);

          this.vision = {
            ...this.vision,
            responsable: this.RESPONSABLES.find(
              (responsable) => responsable.id === this.selectedResponsable
            ),
            organisation: this.ORGANISATIONS.find(
              (organisation) =>
                organisation.id === this.selectedOrganisationResp
            ),
            libelle: this.vision_libelle,
            description: this.vision.description,
            status: this.vision.status,
          };
        });
      }
    },
  },
};
</script>

<style>
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #ffffff;
  border: none;
  color: #9b9b9b;
  text-transform: lowercase;
  font-variant: small-caps;
}

.style-chooser .vs__dropdown-toggle {
  height: 44px;
  width: auto;
  border: 1px rgb(212, 212, 212) solid;
}

.style-chooser .vs__clear,
.style-chooser .vs__open-indicator {
  fill: #000000;
}
</style>
