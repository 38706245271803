<template>
  <div>
    <div class="" style="margin-top: -56px">
      <div class="p-0 w-100 d-flex">
        <div class="row">
          <div class="col-12">
            <p class="h2 text-secondary">
              <i style="font-size: 22px" :class="$route.meta.iconClass"></i>

              <span style="font-size: 22px; color: #7f7f7f" class="font-weight-light">
                Vision :
              </span>
              <span style="font-size: 25px" class="font-weight-bold">{{
                visionName
              }}</span>

              <!-- <span style="font-size: 22px">{{ fdrName }}</span> -->
            </p>
          </div>
        </div>
        <div class="ml-auto" style="margin-right: 45px">
          <!-- <b-button @click="confirmItem" variant="secondary">Confirmer</b-button> -->
          <b-button
            variant="primary"
            pill
            type="submit"
            @click="confirmItem"
            size="sm"
            style="height: 40px; width: 40px"
            class="d-flex align-items-center justify-content-center"
            ><i class="mdi mdi-check"></i
          ></b-button>
        </div>
      </div>
    </div>

    <div class="container-fluid bg-white rounded-lg">
      <div class="row">
        <div class="col-12 p-0 d-flex flex-wrap justify-content-between">
          <span class="h2 mr-auto text-secondary">Nouvel objectif</span>
        </div>
      </div>
    </div>

    <div class="mt-3 d-flex" style="gap: 5px">
      <div class="col-md-6 shdow">
        <div>
          <span class="h4 text-secondary">Informations générales</span>
        </div>
        <hr class="mt-1 mb-3 bg-secondary" />

        <div>
          <b-form-group
            label="Libellé:"
            class="font-weight-bold"
            label-cols-sm="3"
            label-align-sm="left"
          >
            <b-form-input
              class="font-weight-normal"
              placeholder="Libellé"
              v-model="newObjectif.libelle"
              :class="{
                'is-invalid':
                  $v.newObjectif.libelle.$error && $v.newObjectif.libelle.$dirty,
              }"
            />
            <!-- ERROR MESSAGES -->
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.newObjectif.libelle.$dirty"
            >
              {{
                !$v.newObjectif.libelle.required
                  ? "Champ obligatoire"
                  : !$v.newObjectif.libelle.minLength
                  ? `Le champ doit contenir au moins ${$v.newObjectif.libelle.$params.minLength.min} caractères.`
                  : ""
              }}
            </span>
            <!--  -->
          </b-form-group>
        </div>
        <div>
          <b-form-group
            label="Organisation:"
            class="font-weight-bold"
            label-cols-sm="3"
            label-align-sm="left"
          >
            <v-select
              class="font-weight-normal form-control p-0"
              style="padding-right: 0 !important ; border: 1px"
              placeholder="Organisation"
              v-model="newObjectif.organisationResp"
              :options="organisations"
              label="text"
              :reduce="(organisation) => organisation.value"
              :class="{
                'is-invalid':
                  $v.newObjectif.organisationResp.$error &&
                  $v.newObjectif.organisationResp.$dirty,
              }"
            />
            <!-- ERROR MESSAGES -->
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.newObjectif.organisationResp.$dirty"
            >
              {{ !$v.newObjectif.organisationResp.required ? "Champ obligatoire" : "" }}
            </span>
            <!--  -->
          </b-form-group>
        </div>
        <div>
          <b-form-group
            label="Responsable:"
            class="font-weight-bold"
            label-cols-sm="3"
            label-align-sm="left"
          >
            <v-select
              label="text"
              class="font-weight-normal form-control p-0"
              style="padding-right: 0 !important ; border: 1px"
              placeholder="Responsable"
              v-model="newObjectif.responsable"
              :options="responsables"
              :reduce="(responsable) => responsable.value"
              :class="{
                'is-invalid':
                  $v.newObjectif.responsable.$error && $v.newObjectif.responsable.$dirty,
              }"
            />
            <!-- ERROR MESSAGES -->
            <span
              style="font-size: x-small"
              class="text-danger font-weight-normal"
              v-if="$v.newObjectif.responsable.$dirty"
            >
              {{ !$v.newObjectif.responsable.required ? "Champ obligatoire" : "" }}
            </span>
            <!--  -->
          </b-form-group>
        </div>
      </div>
      <div class="col-md-6 shdow">
        <div>
          <span class="h4 text-secondary">Avancement</span>
        </div>
        <hr class="mt-1 mb-3 bg-secondary" />

        <div>
          <b-form-group
            label="Avancement:"
            class="font-weight-bold"
            label-cols-sm="3"
            label-align-sm="left"
          >
            <b-progress
              max="100"
              show-progress
              height="15px"
              style="background-color: #cacccd"
              :value="0"
            >
            </b-progress>
          </b-form-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { required, minLength } from "vuelidate/lib/validators"; //validation

export default {
  name: "Coverage",
  components: {},
  props: {
    responsables: Array,
    organisations: Array,
    visionName: String,
  },
  data: () => ({
    newObjectif: {
      libelle: "",
      responsable: "",
      organisationResp: "",
    },
    delete: false,
    idCouverture: [],
    index: 0,
    editMode: false,
    submitingForm: false,
    coverture: "",
    location: {
      adresse: "",
      country: "",
      lat: "",
      lng: "",
      countryCode: "",
    },
  }),
  validations: {
    newObjectif: {
      libelle: {
        required,
        minLength: minLength(5),
      },
      responsable: {
        required,
      },
      organisationResp: {
        required,
      },
    },
  },
  methods: {
    confirmItem: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store
          .dispatch("objectif/createObjectif", {
            ...this.newObjectif,
            vision: `/api/strategy/visions/${this.$route.params.id}`,
          })
          .then(() => {
            this.$store.dispatch("vision/fetchVision", this.$route.params.id).then(() => {
              this.$emit("close");
              Swal.fire({
                title: "L'objet est bien créé !",
                type: "success",
                confirmButtonText: "OK",
              });
            });
          });
      }
    },
  },
};
</script>
