var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EditGenericCard',{attrs:{"margin":_vm.margin,"editMode":_vm.editMode,"fileModActivated":_vm.fileModActivated},on:{"modifyFileDoc":() => {
      _vm.editMode = !_vm.editMode;
      _vm.$emit('modifyFileDo');
    }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('p',{staticClass:"h4 my-auto",staticStyle:{"margin":"8.078px 0 !important"}},[_vm._v("Avancement")]),_c('div',[((_vm.isAdmin || _vm.isSuper || _vm.isManager) && !_vm.editMode)?_c('a',{staticClass:"mt-2"},[_c('i',{staticClass:"mdi mdi-file-document-edit-outline",staticStyle:{"cursor":"pointer","font-size":"35px","float":"right","margin-top":"-9%"},style:({
            color: _vm.variantr(_vm.disable),
            pointerEvents: _vm.curs(_vm.disable),
            display: _vm.curs(_vm.disableButton),
          }),on:{"click":function($event){(_vm.editMode = !_vm.editMode),
              _vm.visionupdateProgress(),
              _vm.$emit('disableButtons', _vm.editMode)}}})]):(_vm.isAdmin || _vm.isSuper || _vm.isManager)?_c('div',{staticClass:"m-0 d-flex p-0",staticStyle:{"float":"right"}},[_c('b-button',{staticClass:"btn btn-secondary rounded-pill",staticStyle:{"width":"90px"},attrs:{"type":"submit","variant":"secondary","size":"sm","disabled":_vm.submitingForm},on:{"click":function($event){return _vm.test()}}},[(_vm.submitingForm)?_c('b-spinner',{staticClass:"text-white",attrs:{"small":"","label":"Loading..."}}):_vm._e(),_vm._v(" Confirmer")],1),_c('b-button',{staticClass:"btn btn-secondary rounded-pill",staticStyle:{"background-color":"#adb5bd","border-color":"#adb5bd"},attrs:{"size":"sm"},on:{"click":function($event){(_vm.editMode = !_vm.editMode), _vm.$emit('disableButtons', _vm.editMode)}}},[_vm._v("Annuler")])],1):_vm._e()])]},proxy:true},{key:"edit",fn:function(){return [_c('div',{staticClass:"row",staticStyle:{"padding":"0px 20px"}},[_c('div',{staticClass:"col-12 m-0 p-0"},[_c('b-form-group',{staticClass:"font-weight-bold",attrs:{"label":"Début:","label-cols-sm":"3","label-align-sm":"left"}},[_c('date-picker',{staticClass:"col-12 p-0 mb-2 font-weight-normal form-control p-0",staticStyle:{"padding-right":"0 !important","height":"44.22px","border":"1px"},attrs:{"format":"DD/MM/YYYY","placeholder":"Date de début","input-attr":{
              class: {
                'is-invalid': _vm.$v.date.startedat.$error && _vm.$v.date.startedat.$dirty,
                ' form-control': true,
              },
            }},model:{value:(_vm.date.startedat),callback:function ($$v) {_vm.$set(_vm.date, "startedat", $$v)},expression:"date.startedat"}}),(_vm.$v.date.startedat.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.date.startedat.required ? "Champ obligatoire" : "")+" ")]):_vm._e()],1),_c('b-form-group',{staticClass:"font-weight-bold",attrs:{"label":"Fin:","label-cols-sm":"3","label-align-sm":"left"}},[_c('date-picker',{staticClass:"col-12 p-0 mb-2 font-weight-normal",staticStyle:{"padding-right":"0 !important","height":"44.22px","border":"1px"},attrs:{"placeholder":"Date Fin","format":"DD/MM/YYYY","input-attr":{
              class: {
                'is-invalid': _vm.$v.date.endingAt.$error && _vm.$v.date.endingAt.$dirty,
                ' form-control': true,
              },
            }},model:{value:(_vm.date.endingAt),callback:function ($$v) {_vm.$set(_vm.date, "endingAt", $$v)},expression:"date.endingAt"}}),(_vm.$v.date.endingAt.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.date.endingAt.required ? "Champ obligatoire" : _vm.$v.date.endingAt.required && _vm.$v.newInitiative.endingAt.$error ? "La date de fin ne doit pas être antérieur à la date de début" : "")+" ")]):_vm._e()],1)],1)])]},proxy:true},{key:"noEdit",fn:function(){return [_c('div',{staticClass:"my-4"},[_c('span',[_vm._v("Début : ")]),_c('span',{staticClass:"text-black font-weight-bold"},[_vm._v(_vm._s(_vm.vision.startedAt))])]),_c('div',{staticClass:"my-4"},[_c('span',[_vm._v("Fin : ")]),_c('span',{staticClass:"text-black font-weight-bold"},[_vm._v(_vm._s(_vm.vision.endingAt))])]),_c('div',{staticClass:"my-4 d-flex"},[_c('span',[_vm._v("Avancement : ")]),_c('span',{staticClass:"col-6 my-auto ml-1 p-0"},[_c('b-progress',{attrs:{"max":"100","show-progress":"","height":"15px"}},[_c('b-progress-bar',{style:(`background: ${
              _vm.vision.avancement > 20 && _vm.vision.avancement <= 70
                ? '#f5f245'
                : _vm.vision.avancement > 70
                ? '#23d92c'
                : '#f5ba45'
            }`),attrs:{"value":_vm.vision.avancement,"label":`${_vm.vision.avancement}%`}})],1)],1)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }