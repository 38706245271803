<template>
  <EditGenericCardWrapper :margin="margin" :fullWidthMode="fullWidthMode">
    <template v-slot:header>
      <div class="w-100 d-flex m-0 p-0 text-secondary justify-content-between">
        <slot name="title"></slot>
      </div>
      <hr class="mt-1 mb-4 bg-secondary" />
    </template>

    <template v-slot:body>
      <div v-if="editMode">
        <slot name="edit"></slot>
      </div>
      <div v-else>
        <slot name="noEdit"></slot>
      </div>
    </template>
  </EditGenericCardWrapper>
</template>

<script>
// import CountryFlag from "vue-country-flag";
import EditGenericCardWrapper from "./EditGenericCardWrapper.vue";

export default {
  //   name: "Coverage",
  components: {
    // CountryFlag,
    EditGenericCardWrapper,
  },
  props: {
    fullWidthMode: {
      type: Boolean,
      default: false,
    },
    fileModActivated: {
      type: Boolean,
      default: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    margin: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
