var render = function render(){var _vm=this,_c=_vm._self._c;return _c('EditGenericCard',{attrs:{"editMode":_vm.editMode,"fileModActivated":_vm.fileModActivated,"fullWidthMode":false},on:{"modifyFileDoc":() => {
      _vm.editMode = !_vm.editMode;
      _vm.$emit('modifyFileDo');
    }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('p',{staticClass:"h4 my-auto",staticStyle:{"margin":"8.078px 0 !important"}},[_vm._v(" Informations générales ")]),_c('div',{staticClass:"my-auto"},[((_vm.isAdmin || _vm.isSuper || _vm.isManager) && !_vm.editMode)?_c('a',{staticClass:"my-auto"},[_c('i',{staticClass:"mdi mdi-file-document-edit-outline",staticStyle:{"cursor":"pointer","font-size":"35px","float":"right","margin-top":"-9%"},style:({
            color: _vm.variantr(_vm.disable),
            pointerEvents: _vm.curs(_vm.disable),
            display: _vm.curs(_vm.disableButton),
          }),on:{"click":function($event){(_vm.editMode = !_vm.editMode),
              _vm.updateVision(),
              _vm.$emit('disableButtons', _vm.editMode)}}})]):(_vm.isAdmin || _vm.isSuper || _vm.isManager)?_c('div',{staticClass:"m-0 d-flex p-0",staticStyle:{"float":"right"}},[_c('b-button',{staticClass:"btn btn-secondary rounded-pill",staticStyle:{"width":"90px"},attrs:{"type":"submit","variant":"secondary","size":"sm","disabled":_vm.submitingForm},on:{"click":function($event){return _vm.test()}}},[(_vm.submitingForm)?_c('b-spinner',{staticClass:"text-white",attrs:{"small":"","label":"Loading..."}}):_vm._e(),_vm._v(" Confirmer")],1),_c('b-button',{staticClass:"btn btn-secondary rounded-pill",staticStyle:{"background-color":"#adb5bd","border-color":"#adb5bd"},attrs:{"size":"sm"},on:{"click":function($event){(_vm.editMode = !_vm.editMode), _vm.$emit('disableButtons', _vm.editMode)}}},[_vm._v("Annuler")])],1):_vm._e()])]},proxy:true},{key:"edit",fn:function(){return [_c('div',{staticClass:"col-12 m-0 p-0"},[_c('b-form-group',{staticClass:"font-weight-bold col-12 p-0",attrs:{"label":"Libellé:","label-cols-sm":"4","label-align-sm":"left"}},[_c('b-form-input',{staticClass:"form-control",class:{
            'is-invalid':
              _vm.$v.vision.libelle.$error && _vm.$v.vision.libelle.$dirty,
          },attrs:{"type":"text","placeholder":"Libellé"},model:{value:(_vm.vision.libelle),callback:function ($$v) {_vm.$set(_vm.vision, "libelle", $$v)},expression:"vision.libelle"}}),(_vm.$v.vision.libelle.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.vision.libelle.required ? "champ obligatoire" : !_vm.$v.vision.libelle.minLength ? `Le champ doit contenir au moins ${_vm.$v.vision.libelle.$params.minLength.min} caractères.` : "")+" ")]):_vm._e()],1),_c('b-form-group',{staticClass:"font-weight-bold col-12 p-0",attrs:{"label":"Description:","label-cols-sm":"4","label-align-sm":"left"}},[_c('b-form-textarea',{staticClass:"form-control h-100",attrs:{"id":"exampleFormControlTextarea1","rows":"3","placeholder":"Description"},model:{value:(_vm.vision.description),callback:function ($$v) {_vm.$set(_vm.vision, "description", $$v)},expression:"vision.description"}})],1),_c('b-form-group',{staticClass:"font-weight-bold col-12 p-0",attrs:{"label":"Responsable:","label-cols-sm":"4","label-align-sm":"left"}},[_c('div',{staticClass:"font-weight-normal p-0 form-control",staticStyle:{"padding":"0 !important","height":"fit-content"}},[_c('v-select',{staticClass:"bg-transparent",attrs:{"options":_vm.responsables,"placeholder":"Responsable","name":"responsable","label":"full_name","reduce":(responsable) => responsable.id},model:{value:(_vm.selectedResponsable),callback:function ($$v) {_vm.selectedResponsable=$$v},expression:"selectedResponsable"}})],1)]),_c('b-form-group',{staticClass:"font-weight-bold col-12 p-0",attrs:{"label":"Organisation:","label-cols-sm":"4","label-align-sm":"left"}},[_c('div',{staticClass:"font-weight-normal p-0 form-control",class:{
            'is-invalid':
              _vm.$v.selectedOrganisationResp.$error &&
              _vm.$v.selectedOrganisationResp.$dirty,
          },staticStyle:{"padding":"0 !important","height":"fit-content"}},[_c('v-select',{staticClass:"bg-transparent",attrs:{"options":_vm.organisationResp,"placeholder":"Organisation","name":"organisation","label":"libelle","reduce":(organisation) => organisation.id},model:{value:(_vm.selectedOrganisationResp),callback:function ($$v) {_vm.selectedOrganisationResp=$$v},expression:"selectedOrganisationResp"}})],1),(_vm.$v.selectedOrganisationResp.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.selectedOrganisationResp.required ? "champ obligatoire" : "")+" ")]):_vm._e()]),_c('b-form-group',{staticClass:"font-weight-bold col-12 p-0",attrs:{"label":"Statut:","label-cols-sm":"4","label-align-sm":"left"}},[_c('div',{staticClass:"font-weight-normal p-0 form-control",class:{
            'is-invalid': _vm.$v.vision.status.$error && _vm.$v.vision.status.$dirty,
          },staticStyle:{"padding":"0 !important","height":"fit-content"}},[_c('v-select',{staticClass:"font-weight-normal form-control p-0 bg-transparent",staticStyle:{"padding-right":"0 !important","border":"1px"},attrs:{"options":_vm.statusVisions,"placeholder":"Statut"},model:{value:(_vm.vision.status),callback:function ($$v) {_vm.$set(_vm.vision, "status", $$v)},expression:"vision.status"}})],1),(_vm.$v.vision.status.$dirty)?_c('span',{staticClass:"text-danger font-weight-normal",staticStyle:{"font-size":"x-small"}},[_vm._v(" "+_vm._s(!_vm.$v.vision.status.required ? "champ obligatoire" : "")+" ")]):_vm._e()])],1)]},proxy:true},{key:"noEdit",fn:function(){return [_c('p',{staticStyle:{"color":"black"}},[_vm._v(" Libellé : "),_c('span',{staticClass:"font-weight-bold",staticStyle:{"color":"black"}},[_vm._v(_vm._s(_vm.vision.libelle))])]),_c('p',{staticStyle:{"color":"black"}},[_vm._v(" Date de création : "),_c('span',{staticClass:"font-weight-bold",staticStyle:{"color":"black"}},[_vm._v(_vm._s(_vm.moment(_vm.vision.createdAt, "DD/MM/YYYY").format("DD/MM/YYYY")))])]),_c('p',{staticStyle:{"color":"black"}},[_vm._v(" Description : "),_c('span',{staticClass:"font-weight-bold",staticStyle:{"color":"black"}},[_vm._v(_vm._s(_vm.vision.description))])]),_c('p',{staticStyle:{"color":"black"}},[_vm._v(" Responsable : "),(_vm.vision.responsable)?_c('span',{staticClass:"font-weight-bold",staticStyle:{"color":"black"}},[_vm._v(_vm._s(_vm.vision.responsable.firstname + " " + _vm.vision.responsable.lastname))]):_vm._e()]),_c('p',{staticStyle:{"color":"black"}},[_vm._v(" Statut : "),_c('span',{staticClass:"font-weight-bold",staticStyle:{"color":"black"}},[_vm._v(_vm._s(_vm.vision.status))])]),_c('p',{staticStyle:{"color":"black"}},[_vm._v(" Organisation responsable : "),(_vm.vision.organisation)?_c('span',{staticClass:"font-weight-bold",staticStyle:{"color":"black"}},[_vm._v(_vm._s(_vm.vision.organisation.libelle))]):_vm._e()])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }