var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 p-0 h-100",class:{
    shdow: !_vm.$route.path.includes('print'),
  }},[(_vm.fullWidthMode)?_c('b-card',[_c('div',{staticClass:"d-flex justify-content-between align-items-center mb-4"},[_vm._t("header")],2),_vm._t("body")],2):_c('div',{staticClass:"h-100",class:{
      shdow: !_vm.$route.path.includes('print'),
      shadow: _vm.$route.path.includes('print'),
      'p-3': _vm.$route.path.includes('print'),
    }},[_c('div',{staticClass:"justify-content-between align-items-center mb-4"},[_vm._t("header")],2),_vm._t("body")],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }